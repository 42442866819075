<template>
  <!-- <v-btn
    :style="styles"
    color="primary"
    fab
    fixed
    href=""
    rel="noopener"
    right
    target="_blank"
    bottom
  >
    <v-icon class="iconify" data-icon="mdi:facebook-messenger"></v-icon> 
  </v-btn> -->
</template>

<script>
  export default {
    name: 'CoreCta',

    computed: {
      styles () {
        return {
          marginTop: `${this.$vuetify.application.top}px`,
        }
      },
    },
  }
</script>
